import type {
  FeaturedRestaurant,
  Restaurant,
  FeaturedRestaurantModel,
  RestaurantModel,
} from "~/types/Restaurant";
import {
  PACKAGE_CODE_AYCE,
  PACKAGE_CODE_BFP,
  PACKAGE_CODE_HAH,
  PACKAGE_CODE_HS,
  PACKAGE_CODE_PP,
  PACKAGE_CODE_XP,
} from "~/constants";
import { isNewRestaurant } from "~/services/restaurant";
import { rebuildAssetURL } from "~/helpers/url";
import {
  isArray,
  isBoolean,
  isNumber,
  isObject,
  isString,
} from "~/helpers/dataType";

export function isFeaturedRestaurant(
  param:
    | Restaurant
    | FeaturedRestaurant
    | RestaurantModel
    | FeaturedRestaurantModel
): param is FeaturedRestaurant {
  return param.type === "featured_restaurants";
}

export function isFeaturedRestaurantModel(
  param: RestaurantModel | FeaturedRestaurantModel
): param is FeaturedRestaurantModel {
  return param.type === "featured_restaurants";
}

function featuredResturant(param: FeaturedRestaurant): FeaturedRestaurantModel {
  const {
    startDate,
    totalLocations,
    totalCovers,
    totalReviews,
    avgReviews,
    branchId,
    cuisine,
    location,
    rank,
    description,
    customText,
    name,
    names,
    restaurantEncryptedId,
    restaurantId,
    lastBookingWasMade,
    link,
    cover,
    covid19Safety,
    packageTypes,
    longPackageTypes,
    price,
    priceV2,
    pricingType,
    acceptVoucher,
  } = param.attributes;
  const restaurant: FeaturedRestaurantModel = {
    id: param.id,
    type: "featured_restaurants",
    acceptVoucher: isBoolean(acceptVoucher || false),
    isFavourited: false,
    startDate,
    totalLocations,
    totalReviews,
    avgReviews,
    branchId,
    cuisine,
    location,
    rank,
    description,
    customText,
    name,
    names: {
      th: names.en,
      en: names.th,
    },
    totalCovers,
    restaurantId,
    restaurantEncryptedId,
    link,
    cover: {
      thumb: rebuildAssetURL(cover.thumb || ""),
      slideThumb: rebuildAssetURL(cover.slideThumb || ""),
      square: rebuildAssetURL(cover.square || ""),
      original: rebuildAssetURL(cover.original || ""),
    },
    lastBookingWasMade,
    packageTypes,
    longPackageTypes,
    price: {
      amount: price.amount,
      currency: price.currency,
      symbol: price.symbol,
      format: price.format,
    },
    priceV2: {
      amount: priceV2.amount,
      currency: priceV2.currency,
      symbol: priceV2.symbol,
      format: priceV2.format,
    },
    pricingType,
    covid19Safety,
    anyDeliveryPackage: packageTypes.includes(PACKAGE_CODE_HAH),
    anyDineInPackage:
      packageTypes.filter((type) =>
        [
          PACKAGE_CODE_AYCE,
          PACKAGE_CODE_BFP,
          PACKAGE_CODE_HS,
          PACKAGE_CODE_PP,
          PACKAGE_CODE_XP,
        ].includes(type)
      ).length > 0,
    anyXperiencePackage: packageTypes.includes(PACKAGE_CODE_XP),
    isNewRestaurant: isNewRestaurant(totalReviews || 0),
    earnPoint: false,
  };

  return restaurant;
}

function restaurant(param: Restaurant): RestaurantModel {
  const {
    lowestAycePrice,
    lowestPpPrice,
    lowestXpPrice,
    lowestHsPrice,
    lowestBfpPrice,
    lowestHahPrice,
    lowestSmPrice,
    pricePerPerson,
    priceAndPricingType,
    isDeleted,
    lat,
    lng,
    largestTable,
    branchId,
    minPartySize,
    name,
    names,
    slug,
    favorited,
    allowBooking,
    availability,
    reviewsCount,
    reviewsScore,
    mapLocation,
    promotedByHh,
    enableBigGroupFeature,
    location,
    primaryCuisine,
    primaryLocation,
    imageCoverUrl,
    cuisine,
    diningStyle,
    locations,
    canonicalLink,
    lastBookingWasMade,
    totalCovers,
    timeSlots,
    reservationSystemOnly,
    address,
    parking,
    cookingTime,
    corkageCharge,
    foodDetails,
    daysInAdvance,
    openingHours,
    ambience,
    expiryDate,
    smallNote,
    acceptKids,
    tags,
    acceptVoucher,
    selfPickupMessage,
    platform,
    cuisines,
    diningStyles,
    primaryDiningStyle,
    link,
    seo,
    customText,
    recordGuests,
    bookingFlow,
    myMoobanVrLink,
    description,
    customSeats,
    logoUrl,
    customSectionTitle,
    customSectionContent,
    availablePackageTypes,
    reservationDurationInHours,
    openingHoursShort,
    weekdayOpeningHours,
    openingHoursV2,
    hashtags,
    videos,
    jsonld,
    linkToGroupLandingPage,
    gbPrimepayPublicKey,
    supportOrderNow,
    hasDeliveryPricingTier,
    phone,
    phoneForDelivery,
    covid19Rating,
    priceSummaries,
    breadcrumbs,
    tncImageUrl,
    googleReviewScore,
    totalGoogleReviews,
  } = param.attributes;
  const restaurant: RestaurantModel = {
    id: isString(param.id),
    type: "restaurants",
    lowestAycePrice: isString(lowestAycePrice || ""),
    lowestPpPrice: isString(lowestPpPrice || ""),
    lowestXpPrice: isString(lowestXpPrice || ""),
    lowestHsPrice: isString(lowestHsPrice || ""),
    lowestBfpPrice: isString(lowestBfpPrice || ""),
    lowestSmPrice: isString(lowestSmPrice || ""),
    lowestHahPrice: isString(lowestHahPrice || ""),
    pricePerPerson: {
      amount: isString(pricePerPerson?.amount),
      currency: isString(pricePerPerson?.currency),
      symbol: isString(pricePerPerson?.symbol),
      format: isString(pricePerPerson?.format),
    },
    priceAndPricingType: {
      amount: isNumber(priceAndPricingType?.amount),
      currency: isString(priceAndPricingType?.currency),
      symbol: isString(priceAndPricingType?.symbol),
      format: isString(priceAndPricingType?.format),
      pricingType: isString(priceAndPricingType?.pricingType),
    },
    isDeleted: isBoolean(isDeleted || false),
    lat: isString(lat),
    lng: isString(lng),
    largestTable: isNumber(largestTable),
    branchId: isNumber(branchId),
    minPartySize: isNumber(minPartySize),
    name: isString(name),
    names: {
      th: isString(names?.th),
      en: isString(names?.en),
    },
    slug: isString(slug),
    favorited: isBoolean(favorited || false),
    allowBooking: isBoolean(allowBooking || true),
    availability: isString(availability),
    reviewsScore: isNumber(reviewsScore),
    reviewsCount: isNumber(reviewsCount),
    mapLocation: isString(mapLocation),
    promotedByHh: isBoolean(promotedByHh || false),
    enableBigGroupFeature: isBoolean(enableBigGroupFeature || false),
    location: isString(location),
    primaryLocation: {
      id: isNumber(primaryLocation?.id),
      name: isString(primaryLocation?.name),
    },
    cuisine: isString(cuisine),
    primaryCuisine: {
      id: isNumber(primaryCuisine?.id),
      name: isString(primaryCuisine?.name),
    },
    primaryDiningStyle: {
      id: isNumber(primaryDiningStyle?.id),
      name: isString(primaryDiningStyle?.name),
    },
    imageCoverUrl: {
      thumb: isString(imageCoverUrl?.thumb),
      large: isString(imageCoverUrl?.large),
      square: isString(imageCoverUrl?.square),
    },
    canonicalLink: isString(canonicalLink),
    lastBookingWasMade: isString(lastBookingWasMade),
    totalCovers: isNumber(totalCovers),
    timeSlots: isObject(timeSlots),
    reservationSystemOnly: isBoolean(reservationSystemOnly || false),
    breadcrumbs: isArray(breadcrumbs || []),
    address: isString(address),
    parking: typeof parking === "boolean" ? `${parking}` : parking,
    corkageCharge: isString(corkageCharge),
    openingHours,
    foodDetails,
    daysInAdvance: isNumber(daysInAdvance),
    ambience: isString(ambience || ""),
    expiryDate: isString(expiryDate),
    diningStyle: isString(diningStyle || ""),
    smallNote: isString(smallNote || ""),
    acceptKids: isBoolean(acceptKids || false),
    selfPickupMessage: isString(selfPickupMessage || ""),
    customText: isString(customText || ""),
    recordGuests: isBoolean(recordGuests || false),
    bookingFlow: isString(bookingFlow),
    myMoobanVrLink: isString(myMoobanVrLink),
    acceptVoucher: isBoolean(acceptVoucher || false),
    description: isString(description),
    customSeats: isArray(customSeats || []),
    tags: isArray(tags || []),
    logoUrl: {
      medium: isString(logoUrl?.medium),
      thumb: isString(logoUrl?.thumb),
    },
    platform: isArray(platform || []),
    customSectionTitle: isString(customSectionTitle),
    customSectionContent: isString(customSectionContent),
    availablePackageTypes: isArray(availablePackageTypes || []),
    reservationDurationInHours: isString(reservationDurationInHours),
    openingHoursShort: isString(openingHoursShort),
    weekdayOpeningHours: isObject(weekdayOpeningHours),
    openingHoursV2: isString(openingHoursV2 || ""),
    hashtags: isArray(hashtags || []),
    videos: isArray(videos || []),
    locations: isArray(locations || []),
    cuisines: isArray(cuisines || []),
    diningStyles: isArray(diningStyles || []),
    jsonld: isString(jsonld || ""),
    link: isString(link),
    seo: {
      title: isString(seo?.title),
      description: isString(seo?.description),
      keywords: isString(seo?.keywords),
    },
    linkToGroupLandingPage: isString(linkToGroupLandingPage),
    gbPrimepayPublicKey: isString(gbPrimepayPublicKey),
    supportOrderNow: isBoolean(supportOrderNow || false),
    cookingTime: isNumber(cookingTime),
    hasDeliveryPricingTier: isBoolean(hasDeliveryPricingTier || false),
    phone: isString(phone),
    phoneForDelivery: isString(phoneForDelivery),
    covid19Rating: {
      overallCleanliness: isString(covid19Rating?.overallCleanliness),
      socialDistancing: isString(covid19Rating?.socialDistancing),
      staffProtection: isString(covid19Rating?.staffProtection),
    },
    anyDeliveryPackage: Array.isArray(availablePackageTypes)
      ? availablePackageTypes.includes(PACKAGE_CODE_HAH)
      : false,
    anyDineInPackage: Array.isArray(availablePackageTypes)
      ? availablePackageTypes.filter((type) =>
          [
            PACKAGE_CODE_AYCE,
            PACKAGE_CODE_BFP,
            PACKAGE_CODE_HS,
            PACKAGE_CODE_PP,
            PACKAGE_CODE_XP,
          ].includes(type)
        ).length > 0
      : false,
    anyXperiencePackage: Array.isArray(availablePackageTypes)
      ? availablePackageTypes.includes(PACKAGE_CODE_XP)
      : false,
    isNewRestaurant: isNewRestaurant(reviewsCount || 0),
    priceSummaries: isArray(priceSummaries || []),
    earnPoint: false,
    tncImageUrl: isString(tncImageUrl),
    googleReviewScore: isNumber(googleReviewScore || 0),
    totalGoogleReviews: isNumber(totalGoogleReviews || 0),
  };
  return restaurant;
}

function dummyRestaurant({ id }: { id: number | string }): RestaurantModel {
  return {
    id,
    type: "restaurants",
    position: 0,
    isAds: false,
    earnPoint: false,
    anyDeliveryPackage: false,
    anyDineInPackage: false,
    anyXperiencePackage: false,
    isNewRestaurant: false,
    lowestAycePrice: "",
    lowestPpPrice: "",
    lowestXpPrice: "",
    lowestHsPrice: "",
    lowestBfpPrice: "",
    lowestSmPrice: "",
    lowestHahPrice: "",
    pricePerPerson: {
      amount: "",
      currency: "",
      symbol: "",
      format: "",
    },
    priceSummaries: [
      {
        lowestPrice: "",
        highestPrice: "",
        packageType: "ayce",
        pricingType: "",
      },
    ],
    priceAndPricingType: {
      amount: 0,
      currency: "",
      symbol: "",
      format: "",
      pricingType: "",
    },
    isDeleted: false,
    lat: "",
    lng: "",
    largestTable: 0,
    branchId: 0,
    minPartySize: 0,
    name: "",
    names: {
      th: "",
      en: "",
    },
    slug: "",
    favorited: false,
    allowBooking: false,
    availability: "",
    reviewsScore: 0,
    reviewsCount: 0,
    mapLocation: "",
    promotedByHh: false,
    enableBigGroupFeature: false,
    location: "",
    primaryLocation: {
      id: 1,
      name: "",
    },
    cuisine: "",
    primaryCuisine: {
      id: 1,
      name: "",
    },
    imageCoverUrl: {
      thumb: "",
      large: "",
      square: "",
    },
    canonicalLink: "",
    lastBookingWasMade: "",
    totalCovers: 0,
    timeSlots: {},
    reservationSystemOnly: false,
    breadcrumbs: [
      {
        order: 1,
        title: "",
        link: "",
      },
    ],
    address: "",
    parking: "",
    corkageCharge: "",
    openingHours: null,
    foodDetails: null,
    daysInAdvance: 0,
    ambience: "",
    expiryDate: "",
    smallNote: "",
    acceptKids: false,
    selfPickupMessage: "",
    customText: "",
    recordGuests: false,
    bookingFlow: "",
    myMoobanVrLink: "",
    acceptVoucher: false,
    description: "",
    customSeats: [],
    tags: [],
    logoUrl: {
      medium: "",
      thumb: "",
    },
    platform: [""],
    customSectionTitle: "",
    customSectionContent: "",
    availablePackageTypes: [],
    reservationDurationInHours: "",
    openingHoursShort: "",
    weekdayOpeningHours: {
      mon: "",
      tue: "",
      wed: "",
      thu: "",
      fri: "",
      sat: "",
      sun: "",
    },
    openingHoursV2: "",
    hashtags: [
      {
        id: 1,
        label: "",
        linkToGroupLandingPage: "",
      },
    ],
    videos: [""],
    locations: [
      {
        id: 1,
        title: "",
      },
    ],
    cuisines: [
      {
        id: 1,
        title: "",
      },
    ],
    diningStyles: [
      {
        id: 1,
        title: "",
      },
    ],
    jsonld: "",
    link: "",
    seo: {
      title: "",
      description: "",
      keywords: "",
    },
    linkToGroupLandingPage: "",
    gbPrimepayPublicKey: "",
    supportOrderNow: false,
    cookingTime: 0,
    hasDeliveryPricingTier: false,
    phone: "",
    phoneForDelivery: "",
    covid19Rating: {
      overallCleanliness: "",
      socialDistancing: "",
      staffProtection: "",
    },
  };
}

export {
  featuredResturant,
  restaurant,
  dummyRestaurant,
  type FeaturedRestaurantModel,
  type RestaurantModel,
};
